import React, { useEffect, useMemo, useState } from 'react'
import axios from "axios";
import {
    Center,
    Spinner,
    Table,
    Tbody,
    Thead,
    Tr,
    Th,
    Td,
    Flex,
    // Button,
    Tooltip,
    IconButton,
    Text,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Image,
    Box,
    // TableContainer
} from '@chakra-ui/react';
import { useTable, usePagination } from 'react-table'
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { CSVLink } from "react-csv";
import Logo from "../images/KMsoftLogo.png";
import file from '../images/file.png'
import "./Tabel.css"

const tableColumn = [
    {
        Header: "Index",
        accessor: "row",
        Cell: ({ row }) => {
            return <div>{row.index + 1}</div>;
        }
    },
    {
        Header: 'fullName',
        accessor: 'fullName'
    },
    {
        Header: 'email',
        accessor: 'email'
    },
    {
        Header: 'phone Number',
        accessor: 'phoneNumber'
    },
    {
        Header: 'note',
        accessor: 'note',
    },
    {
        Header: 'language',
        accessor: 'lang',
    },
    {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => `${row.values.date.slice(0, 10)}`
    },
    {
        Header: 'time',
        accessor: 'time',
        Cell: ({ row }) => `${row.values.date.slice(11, 16)}`
    },
]

const TableData = () => {

    // console.log(process.env.REACT_APP_GET_DATA);

    const API_KEY = process.env.REACT_APP_GET_DATA

    const [Data, setData] = useState([])

    const columns = useMemo(() => tableColumn, [])
    const data = useMemo(() => Data, [Data])
    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        previousPage,
        nextPage,
        pageCount,
        pageOptions,
        setPageSize,
        state: {
            pageIndex, pageSize
        }
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    },
        usePagination
    )

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(API_KEY)
                setData(data.reverse())
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()
    },[])

    // console.log(Data);

    if (Data.length === 0) {
        return (
            <Center>
                <Spinner />
            </Center>
        )
    }



    return (
        <Box>
            <Flex justifyContent={"center"}>
                <Image src={Logo} w="386px" />
            </Flex>
            <Flex justify={"space-between"}>
                <Flex align="center" ms={"4"}>
                    <CSVLink data={Data} filename="Data" className="button-CSV d-flex position-relative" >
                        <div><Image src={file} w="30px" m={"0"} p={"0"} top={"5px"} left={'5px'} position={"absolute"} /></div>
                        <div className='align-self-center fw-bold ms-4 ps-1'>Download</div>
                    </CSVLink>
                </Flex>
                <Flex justify="center" align="center">
                    <Flex gap="4" m={"4"}>
                        <Tooltip label="First Page">
                            <IconButton onClick={() => gotoPage(0)} icon={<ArrowLeftIcon h="3" w="3" />} />
                        </Tooltip>
                        <Tooltip label="Prev Page">
                            <IconButton onClick={previousPage} icon={<ChevronLeftIcon h="4" w="4" />} />
                        </Tooltip>
                    </Flex>

                    <Flex align="center" gap="2">
                        <Flex align="center" gap="1">
                            Page
                            <Text fontWeight="bold" as="span">
                                {pageIndex + 1}
                            </Text>
                            of
                            <Text fontWeight="bold" as="span">
                                {pageOptions.length}
                            </Text>
                        </Flex>
                        <NumberInput w="28" min={1} max={pageOptions.length} onChange={(value) => {
                            const page = value ? value - 1 : 0;
                            gotoPage(page)
                        }} defaultValue={pageIndex + 1}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <Select w="32" value={pageSize} onChange={
                            (e) => { setPageSize(Number(e.target.value)) }}>
                            {[10, 30, 50, 100].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>))}
                        </Select>
                    </Flex>

                    <Flex gap="4" m={"4"}>
                        <Tooltip label="Next Page">
                            <IconButton onClick={nextPage} icon={<ChevronRightIcon h="4" w="4" />} />
                        </Tooltip>
                        <Tooltip label="Last Page">
                            <IconButton onClick={() => { gotoPage(pageCount - 1) }} icon={<ArrowRightIcon h="3" w="3" />} />
                        </Tooltip>
                    </Flex>
                </Flex>
            </Flex>

            {/* <TableContainer> */}
            <Table size='sm' {...getTableProps} variant='striped' colorScheme="gray" border={"1px solid #edf2f7"} mt={'5'} >
                <Thead >
                    {
                        headerGroups.map((headerGroups) => (
                            <Tr {...headerGroups.getHeaderGroupProps()}>
                                {headerGroups.headers.map((column, index) => {
                                    return (
                                        <>
                                            <Th {...column.getHeaderProps()} color={"black"} className="p-0 m-0 py-2 ps-3 text-nowrap">
                                                {column.render("Header")}
                                            </Th>
                                        </>
                                    )
                                })}
                            </Tr>
                        ))
                    }

                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {
                        page.map((row) => {
                            prepareRow(row)
                            return <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <>
                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                    </>
                                })}
                            </Tr>
                        })
                    }
                </Tbody>
            </Table>
            {/* </TableContainer> */}
        </Box >
    )
}

export default TableData