import React from "react";
import { useNavigate } from "react-router-dom";

// css
import "./EnrollNow.css";

// Image
import KmSoftLogo from '../images/Group25.png';
import Add from '../images/institiute/Add.png';
import Call from '../images/institiute/Call.png';
import Email from "../images/institiute/Email.png";

const EnrolNow = (props) => {

    const navigate = useNavigate()

    const EnrollNow = () => {
        navigate('./home')
    }

    return (
        <div>

            <section className="mainbox ">
                <div className="container ">

                    <div className="text-center mt-1  py-2 logocont ">
                        <img src={KmSoftLogo} width="451px" height="124px" className=" d-block mx-auto img-fluid" alt="" />
                    </div>

                    <div className="row mt-4">
                        <div className="text-center" >

                            <h1 className="card-title title-tex Software">Software Development</h1>

                            <p className="card-title title-tex2 Software1 mt-4" >માં તમે પણ બનાવો તમારૂ ઉજ્જવળ કરિયર ....</p>

                            <div className="mt-3">
                                <button type="button" className="button-style-of-EnrollNow mx-auto d-block" onClick={EnrollNow}>Enroll Now</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container d-flex justify-content-center mt-5 ">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <a href="https://www.google.com/maps/place/KMSOFT/@21.235136,72.858897,15z/data=!4m5!3m4!1s0x0:0x9a6b05a4b94c7d8!8m2!3d21.235193!4d72.8589295?hl=en" className="Details">
                                <div className="d-flex bg-Details rounded-3 p-1">
                                    <div className="loc">
                                        <img src={Add} alt="" />
                                    </div>
                                    <div className="add">
                                        <p> Address</p>
                                        <div className="loction">
                                            <p>110-11,Amby Vally Arcade,OppSentosa Hights,<br />
                                                Green Road,Kapodra-Utran Bridge,Surat-394105</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="tel:9484598805" className="Details">
                                <div className="d-flex bg-Details rounded-3 p-1">
                                    <div className="loc">
                                        <img src={Call} alt="" />
                                    </div>
                                    <div className="add">
                                        <p> Call us</p>
                                        <div className="loction">
                                            <p>+91 9484598805</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="mailto:kmsoft2013@gmail.com" className="Details">
                                <div className="d-flex bg-Details rounded-3 p-1">
                                    <div className="loc">
                                        <img src={Email} alt="" />
                                    </div>
                                    <div className="add">
                                        <p>Email</p>
                                        <div className="loction">
                                            <p>kmsoft2013@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mt-3 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                            <div className="d-flex justify-content-center">
                                <iframe
                                    title="map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.916968882006!2d72.85670841500179!3d21.23514088607646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f3c627dce81%3A0x9a6b05a4b94c7d8!2sKMSOFT!5e0!3m2!1sen!2sin!4v1672286089759!5m2!1sen!2sin"
                                    width="327" height="280 " loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EnrolNow