import React, { useState } from "react"
import Toast from 'react-bootstrap/Toast';
import { useNavigate } from "react-router-dom";
//Css
import "./Home.css";
// image part
import KmSoftLogo from '../images/Group25.png';
import C from '../images/C.png';
import Cpp from '../images/C++.png';
import Java from '../images/java.png';
import Android1 from '../images/Android.png';
import Kotlin from '../images/Kotlin.png';
import Ios from '../images/Apple.png';
import Swift from '../images/swift.png';
import Flutter from '../images/flutter.png';
import ReactNative from '../images/react-1.png';
import ReactJs from '../images/react.png';
import Php from '../images/Php.png';
import NodeJs from '../images/nodejs.png';
import Mongodb from '../images/mongo.png';
import SQL from '../images/MySQL.png';
import DataStucture from '../images/DataStucture.png';

const photo = 'image'

const Home = (props) => {

    const ImagesRow1 = [
        { id: 1, image: C, name: "C", },
        { id: 2, image: Cpp, name: "C++", },
        { id: 3, image: Java, name: "Java", },
        { id: 4, image: Android1, name: "Android", },
        { id: 5, image: Kotlin, name: "Kotlin", },
        { id: 6, image: Ios, name: "Ios", },
        { id: 7, image: Swift, name: "Swift", },
        { id: 8, image: Flutter, name: "Flutter", },
        { id: 9, image: ReactNative, name: "ReactNative", },
        { id: 10, image: ReactJs, name: "ReactJs" },
        { id: 11, image: Php, name: "Php", },
        { id: 12, image: NodeJs, name: "NodeJs", },
        { id: 13, image: Mongodb, name: "Mongodb", },
        { id: 14, image: SQL, name: "SQL", },
        { id: 15, image: DataStucture, name: "DataStucture", },
    ];

    const [state, setState] = useState([]);

    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const handleOnSubmit = (event) => {
        if (!document.querySelector(':checked')) {
            setShow(true)
            return false;
        }
        event.preventDefault();
        navigate('/form',{state});
    };

    const handleInputChange = (event) => {
        // console.log(event);
        const value = event
        if (state.filter(obj => value.id === obj.id).length > 0) {
            var array = state;
            var index = array.indexOf(value);
            if (index !== -1) {
                array.splice(index, 1);
                // console.log("Remove: " + index);
            }
            setState(array);
            // console.log("Out: ");
        }
        else {
            var array1 = state;
            array1.push(value);
            setState([...state, value])
            setState(array1);
            // console.log("In: ");
        }
        state.forEach(element => {
            // console.log("final array "+element.name);
        });
        // console.log("States: " + state);
    };

    var images = ImagesRow1.map((image) => {
        return (
            <div className='col-auto' key={image.id}>
                <label>
                    <div className='text-center imageAndTextDiv'>
                        <div className="p-0 m-0" style={{ position: 'absolute', top: '10px', right: '15px', }}>
                            <input type="checkbox" value={image} onChange={() => { handleInputChange(image) }} className="form-check-input" />
                        </div>
                        <div>
                            <img src={image.image} alt={photo} height="91px" width="90px" className="mx-auto d-block" />
                        </div>
                        <div style={{ margin: '32px 0px 0px 0px' }}>
                            <p>{image.name}</p>
                        </div>
                    </div>
                </label>
            </div>
        )
    });

    return (
        <div className="position-relative font-family">
            <div className="kmsoft mb-4 text-center">
                <img type="photo" src={KmSoftLogo} height="106px" width="386px" alt={photo} className="mx-auto d-block img-fluid" />
                {/* <h5 className="mt-0 INSTITUTE-OF-TECHNOLOGY">INSTITUTE OF TECHNOLOGY</h5> */}
            </div>
            <div className='container pb-5'>
                <div className='row justify-content-center'>{images}</div>
            </div>
            <div className='text-center mt-4 position-of-button bg-white position-fixed w-100'>
                <button type="button" className="button-style-of-next mx-auto d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none" onClick={handleOnSubmit} style={{ backgroundColor: '#22A3C8', color: '#fff', }}>Next </button>
            </div>
            <div>
                <button type="button" className="button-style-of-next mx-auto d-block d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block" onClick={handleOnSubmit} style={{ backgroundColor: '#22A3C8', color: '#fff', }}>Next </button>
                {[
                    'Danger',
                ].map((variant, idx) => (
                    <Toast bg={variant.toLowerCase()} key={idx} className="position-fixed Tost-position" onClose={() => setShow(false)} show={show} delay={3000} autohide >
                        <Toast.Header>
                            <strong className="me-auto">Error</strong>
                        </Toast.Header>
                        <Toast.Body className="text-white fs-5">Please select at least one option.</Toast.Body>
                    </Toast>
                ))}
            </div>
        </div>
    )
}

export default Home;