import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
// import {env} from "../../../"
// require('dotenv').config()

//Css
import "./Form.css";
// images
import KmSoftLogo from '../images/Group25.png';
import ModalIcon from "../images/Model-icon.png";

const photo = 'image'

const Form = (props) => {


    console.log(process.env.REACT_APP_API_KEY);

    const API_KEY = process.env.REACT_APP_USER_CREATEUSERS

    const navigate = useNavigate()

    const location = useLocation();
    var AllData = []
    AllData = location.state
    // console.log();
    const SelectdData = AllData.map((data) => {
        return (
            <div key={data.id} className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mt-3 mt-lg-4">
                <div className="Box pt-4 text-center">
                    <div className="Box-image-size m-auto">
                        <img src={data.image} height="100%" alt={photo} width="100%" />
                    </div>
                    <p className="Box-Text-size mb-3 mt-3">{data.name}</p>
                </div>
            </div>
        )
    })
    const SriptData = AllData.map((data) => data.name)
    // console.log(SriptData);

    // var emailValidation = `^[a-zA-Z][-_.a-zA-Z0-9]{5,29}@g(oogle)?mail.com$`

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required('Full name is required'),
        email: Yup.string()
            .required('Email is required')
            // .matches(emailValidation ,'enter a valid email like this (...@gmail.com)')
            .email('Email is invalid'),
        phoneNumber: Yup.string()
            .required('Contact number is required')
            .min(10, 'number must be at least 10 characters')
            .max(10, 'number must not exceed 10 characters'),
        note: Yup.string(),
        lang: Yup.string()
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    console.log(process.env.REACT_APP_USER_CREATEUSERS);
    // `${"https://edu.kmsoft.org/user/createUsers"}` &&   
    const onSubmit = (data) => {
        // console.log((data));
        fetch(API_KEY, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(response => {
            // console.log(response)
            handleShow()
        })
            .catch(error => {
                console.log(error)
            })
    };

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        navigate('/')
    };
    const handleShow = () => setShow(true);

    return (
        <div className="font-family-form">
            <div className="container">
                <div className="kmsoft text-center">
                    <img type="image" src={KmSoftLogo} height="106px" width="386px" alt={photo} className="mx-auto d-block img-fluid" />
                    {/* <h5 className="mt-0 INSTITUTE-OF-TECHNOLOGY">INSTITUTE OF TECHNOLOGY</h5> */}
                </div>
                <div className="text-center my-4"><p className="fs-4">Enter your details</p></div>
                <div className="register-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                <div className="form-group">
                                    <label className="Font-size-of-form">Full name<span className="text-danger">*</span></label>
                                    <input
                                        name="fullName"
                                        type="text"
                                        {...register('fullName')}
                                        className={`form-control border-0 bg-light ${errors.fullName ? 'is-invalid' : ''}`}
                                        placeholder="Full Name"
                                    />
                                    <div className="invalid-feedback">{errors.fullName?.message}</div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 my-2">
                                <div className="form-group">
                                    <label className="Font-size-of-form">Email<span className="text-danger">*</span></label>
                                    <input
                                        name="email"
                                        type="text"
                                        {...register('email')}
                                        className={`form-control border-0 bg-light ${errors.email ? 'is-invalid' : ''}`}
                                        placeholder="Enter Email"
                                    />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                <div className="form-group">
                                    <label className="Font-size-of-form">Contact no.<span className="text-danger">*</span></label>
                                    <input
                                        type="text" inputMode="numeric" name="country_code" title="Error Message" maxLength={10} pattern="[1-9]{1}[0-9]{9}"
                                        {...register('phoneNumber')}
                                        className={`form-control border-0 bg-light ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                        placeholder="Enter contact number"
                                    />
                                    <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
                                </div>
                            </div>
                            <div className="form-group mt-2">
                                <label className="Font-size-of-form">Note</label>
                                <textarea
                                    name="text"
                                    type="textarea"
                                    {...register('note')}
                                    className="form-control border-0 bg-light"
                                    inputMode="text"
                                />
                                {/* <div className="invalid-feedback">
                                    {errors.note?.message}
                                </div> */}
                            </div>
                            <input value={SriptData} {...register('lang')} className="d-none" />
                            <div className="form-group text-center mt-4">
                                <button type="submit" className="button-style-of-Submit">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    <Modal show={show} onHide={handleClose} {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body className="p-0 text-center">
                            <div className="d-flex justify-content-center">
                                <div className="model-Icon text-center">
                                    <img src={ModalIcon} alt={photo} height="100%" width="100%" />
                                </div>
                            </div>
                            <div>
                                <p className="model-titel-text p-0 m-0">Great !</p>
                                <p className="fs-4">Your response is successfully submitted.</p>
                                <button partname="/" type="button" className="btn btn-danger1 px-5 fs-5 mb-5 w-25" onClick={handleClose}>OK</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <div className="row justify-content-center">
                    {SelectdData}
                </div>
            </div>
        </div>
    )
}

export default Form;