import React, { useState } from "react";
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";

// CSS
import './Login.css'

//Image
import KmSoftLogo from '../images/Group25.png';

const photo = 'image'

const Login = () => {

    var [show, setShow] = useState(false);

    const API_KEY = process.env.REACT_APP_USER_LOGIN

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Full name is required'),
        // email: Yup.string().required('Email is required').email("Email is invalid"),
        password: Yup.string().required('Password is required')
    });
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function PostData(userData) {
        console.log("userData", userData);
        return new Promise((resolve, reject) => {
            fetch(API_KEY, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: JSON.stringify(userData),
            })
                .then(response => response.json())
                .then(responseJson => {
                    resolve(responseJson)
                    // onSubmit(userData)
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    var Errorshow = () => {
        console.log(show[1]);
        return (
            <div className="error" style={{ display: show ? '' : 'none', }}>
                {/* <div class="alert alert-danger d-flex align-items-center" role="alert">
                    <svg class="bi flex-shrink-0 me-2" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill" /></svg>
                    <div>
                        An example danger alert with an icon
                    </div>
                </div> */}
                <div className="alert alert-danger" role="alert">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" width={"10px"} viewBox="0 0 16 16" role="img" aria-label="Warning:">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />

                    </svg> */}
                    <div>{show[1]}</div>
                </div>
            </div>
        );
    }

    const onSubmit = (data) => {
        console.log(data);
        PostData(data).then(result => {
            console.log(result);
            let key = result.token
            if (data.username === result.username || data.password === result.password) {
                console.log("i am ok");
                if (result.token) {
                    sessionStorage.setItem('login', key)
                    navigate('/tabel')
                }
                else {
                    console.log('note ok');
                }
            }
            else {
                console.log("i am note ok");
                setShow(Errorshow())
                setShow([show, result])
            }
        });
    };

    return (
        <div>
            <div className="container height-width">
                <div className="kmsoft mb-4 text-center pb-5">
                    <img type="photo" src={KmSoftLogo} height="106px" width="386px" alt={photo} className="mx-auto d-block img-fluid" />
                </div>
                <div className="bg-light p-5 p-sm-5 p-md-5 p-lg-5 p-xl-5 p-xxl-5 rounded-5 mx-auto d-block width-m" >
                    <div><p className="fw-bold fs-4">Login</p></div>

                    {Errorshow()}

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label className="w-100">
                                User name
                                <input type="text" {...register('username')}
                                    className={`form-control border-1 bg-light ${errors.username ? 'is-invalid' : ''}`}
                                    placeholder="username" />
                            </label>
                            {/* <label className="w-100">
                                Email
                                <input type="text" {...register('email')}
                                    className={`form-control border-1 bg-light ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder="username" />
                            </label> */}
                        </div>
                        <div className="mb-3">
                            <label className="w-100">
                                Password
                                <input type="password" title="Error Message"   {...register('password')}
                                    className={`form-control border-1 bg-light ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder="password" />
                            </label>
                        </div>
                        <button type="submit" className="btn fw-bold pt-2">Submit</button>
                    </form>
                </div>
            </div>
        </div>

    )

}

export default Login;