import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Components/Page-1/Home";
import Form from "./Components/Page-2/Form";
import EnrollNow from "./Components/EnrollNow/EnrollNow";
import LoginPage from "./Components/LoginPage/Login";
import Tabel from "./Components/Tabel/Tabel";
import './App.css';
import Protected from "./Protected";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/*" exact element={<EnrollNow />} />
        <Route path="/home" element={<Home />} />
        <Route path="/form" element={<Form />} />

        {/* <Route path="/t"  /> */}

        <Route path="/login" element={<LoginPage />}/>
        <Route path="/tabel" element={<Protected Component={Tabel} />} />
      </Routes>
    </Router>
  );
}

export default App;
